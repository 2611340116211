import axios from 'axios'

export class Coordenador {
  static async obtemTodos (ano) {
    return axios.get('/coordenador/todos/'+ano)
  }
  static async obtemUm (id) {
    return axios.get('/coordenador/'+id)
  }
  static async obtemUmServidor (id) {
    return axios.get('/coordenador/servidor/'+id)
  }
  static async obtemTodosTurma (payload) {
    return axios.get('/coordenador/turmas/'+payload.escola_id+'/'+payload.ano+'/'+payload.servidor_id)
  }
  static async obtemTodosTurmaCoordenacao (payload) {
    return axios.get('/coordenador/turmas/'+payload.ano+'/s/'+payload.servidor_id)
  }
  
  static async cadastrar (payload) {
    return axios.post('/coordenador', payload)
  }
  static async alterar (payload,) {
    return axios.put('/coordenador/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/coordenador/'+id)
  }
  static async buscarCoordenadorNaoHabilitados () {
    return axios.get('coordenador/naoHabilitado');
  } 
}